export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
    },
  },
  {
    component: 'CNavItem',
    name: 'Audit Trail',
    to: '/history',
    icon: 'cil-calculator',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  {
    component: 'CNavTitle',
    name: 'User Management',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-cursor',
  },
  {
    component: 'CNavTitle',
    name: 'Master Data',
  },
  {
    component: 'CNavItem',
    name: 'Comment Log',
    to: '/comment',
    icon: 'cil-calculator',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Master Document',
  //   to: '/document',
  //   icon: 'cil-file',
  // },
  {
    component: 'CNavItem',
    name: 'Catalog Profile',
    to: '/catprof',
    icon: 'cil-file',
  },
  {
    component: 'CNavItem',
    name: 'FS Code',
    to: '/fscode',
    icon: 'cil-bell',
  },
  {
    component: 'CNavTitle',
    name: 'Working Space',
  },
  {
    component: 'CNavItem',
    name: 'ECA Worksheet',
    to: '/worksheet',
    icon: 'cil-pencil',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'ECA Main',
  //   to: '/eca',
  //   icon: 'cil-puzzle',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'FSCA Worksheet',
  //   to: '/fsca-worksheet',
  //   icon: 'cil-pencil',
  //   badge: {
  //     color: 'primary',
  //     text: 'NOT AVAILABLE',
  //   },
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'FSCA Main',
  //   to: '/fsca',
  //   icon: 'cil-calculator',
  //   badge: {
  //     color: 'primary',
  //     text: 'NOT AVAILABLE',
  //   },
  // }
]
