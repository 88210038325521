import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/user/User.vue'),
      },
      {
        path: '/worksheet',
        name: 'Worksheet',
        component: () => import('@/views/worksheet/ECAWorksheet.vue'),
      },
      {
        path: '/fsca-worksheet',
        name: 'FSCA Worksheet',
        component: () => import('@/views/worksheet/FSCAWorksheet.vue'),
      },
      {
        path: '/fsca',
        name: 'FSCA Main',
        component: () => import('@/views/fsca/FSCAMain.vue'),
      },
      {
        path: '/eca',
        name: 'ECA Main',
        component: () => import('@/views/eca/EcaMain.vue'),
      },
      {
        path: '/comment',
        name: 'Comment Master',
        component: () => import('@/views/master/Comment.vue'),
      },
      {
        path: '/document',
        name: 'Document',
        component: () => import('@/views/master/MasterDocument.vue'),
      },
      {
        path: '/catprof',
        name: 'Catalog Profile Master',
        component: () => import('@/views/master/CatProf.vue'),
      },
      {
        path: '/fscode',
        name: 'FS Code Master',
        component: () => import('@/views/master/FSCode.vue'),
      },
      {
        path: '/history',
        name: 'Audit Trail',
        component: () => import('@/views/history/History.vue'),
      },
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
